var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { CONTAINER_FILLING_LABELFORMAT, MAINTAIN_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "add-specification card" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("div", { staticClass: "p-4" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("cf-select-input", { attrs: { "all-in-value": "", "form-item": "", "rules": "required", "label": "Specification", "option-label-prop": "value", "placeholder": "Choose Specification", "reference": "cf.common.specifications", "source": "code", "search-by": "Code", "delete-filter": "IsInactive", "filter-type": "StartsWith", "api-not-get-paging": "" }, on: { "change": function($event) {
      return _vm.storeValue("specificationId", $event ? $event.id : void 0);
    } }, scopedSlots: _vm._u([{ key: "data-source", fn: function(ref2) {
      var options = ref2.options;
      return [_c("a-select-option", { attrs: { "value": "labelSelectOption", "disabled": "" } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 6 } }, [_vm._v("Code")]), _c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 18 } }, [_vm._v("Description")])], 1)], 1), _vm._l(options, function(option) {
        return _c("a-select-option", { key: option.id, attrs: { "value": option.code } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 6 } }, [_vm._v(_vm._s(option.code))]), _c("a-col", { attrs: { "span": 18 } }, [_vm._v(_vm._s(option.description))])], 1)], 1);
      })];
    } }], null, true) })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("switch-input", { attrs: { "form-item": "", "label": "Detail Label", "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("detailLabel", $event);
    } } })], 1)], 1)], 1), _c("div", { staticClass: "ant-modal-footer p-3" }, [_c("a-button", { on: { "click": _vm.onBack } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": function($event) {
      return handleSubmit(_vm.onCreateClick);
    } } }, [_vm._v("Add")])], 1)];
  } }]) })], 1);
};
var staticRenderFns$5 = [];
var CreateLabel_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$5 = {
  name: "CreateLabel",
  inject: ["crud"],
  data() {
    return {
      customUrl: `${apiUrl$3}/cf/label-formats/${this.$route.params.id}/labels`,
      createForm: {},
      colSpecs: [{ name: "description", span: 18 }],
      isLoading: false
    };
  },
  destroyed() {
    this.crud.clearEntity("createForm");
  },
  methods: {
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onCreateClick() {
      this.isLoading = true;
      this.crud.submitEntity("create", this.customUrl).then(() => {
        this.isLoading = false;
        this.$emit("reload");
      }).catch(() => this.isLoading = false);
    },
    onBack() {
      this.$router.push("/container-filling/label-formats");
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "446e7ca2", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var CreateLabel$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-label" }, [_c("resource", { attrs: { "name": "cf.common.specifications", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.label-formats.labels", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/label-formats" } }, [_c("create-label", { on: { "reload": _vm.onReload } })], 1)], 1);
};
var staticRenderFns$4 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    CreateLabel: CreateLabel$1
  },
  data() {
    return {
      CreateLabel: CreateLabel$1,
      apiUrl: apiUrl$2
    };
  },
  methods: {
    onReload() {
      this.$emit("reload");
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var CreateLabel = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "custom-url": _vm.customUrl, "search-by": "SpecificationCode", "ph-search-by": "Search by Specification", "has-active": false, "has-filter-inactive": false, "has-edit-in-row": true, "custom-url-edit-in-row": _vm.customUrlEditInRow, "has-delete": true, "label-item-count": "Specification List" } }, [_c("text-field", { key: "SpecificationCode", attrs: { "data-index": "specificationCode", "title": "Specification", "fixed": "left", "edit-in-row": "auto-complete-input", "edit-in-row-data": _vm.editSpecCodeData, "width": 150, "sorter": true } }), _c("text-field", { key: "Customer", attrs: { "data-index": "customer", "title": "Customer", "width": 240 } }), _c("icon-field", { key: "DetailLabel", attrs: { "data-index": "detailLabel", "title": "Detail Label", "align": "center", "edit-in-row": "switch-input", "width": 120, "render-icon": _vm.renderIcon } }), _c("text-field", { key: "NoCopies", attrs: { "data-index": "noCopies", "title": "No of Copies", "edit-in-row": "text-input", "width": 145, "sorter": true } })], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "ListLabel",
  inject: ["customUrl"],
  data() {
    return {
      count: 0,
      customUrlEditInRow: `${this.customUrl}/RECORD_ID`,
      editSpecCodeData: {
        reference: "cf.common.specifications",
        source: "id",
        sourceLabel: "code",
        searchBy: "Code",
        placeholder: "Choose Specification",
        deleteFilter: "IsInactive",
        sourcePath: "SpecificationId"
      }
    };
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListLabel$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-label" }, [_c("resource", { attrs: { "page": _vm.page, "name": "cf.label-formats.labels", "api-url": _vm.apiUrl } }, [_c("list-label")], 1), _c("router-view")], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ListLabel: ListLabel$1
  },
  provide() {
    return {
      customUrl: `${this.apiUrl}/cf/label-formats/${this.$route.params.id}/labels`
    };
  },
  data() {
    return {
      page: CONTAINER_FILLING_LABELFORMAT,
      apiUrl: apiUrl$1
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListLabel = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "process-label-format" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "header px-5 pt-3 pb-4 mb-5 d-flex justify-content-between align-items-center" }, [_c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [_c("a-icon", { staticClass: "arrow-left pr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("span", { staticClass: "header-title" }, [_vm._v("Label Format")]), _vm.entity && _vm.entity.name ? _c("span", { staticClass: "pl-1 pr-3 header-title" }, [_vm._v(_vm._s(" - " + _vm.entity.name))]) : _vm._e(), _vm.entity && _vm.entity.template ? _c("span", { staticClass: "entity-color" }, [_vm._v(_vm._s(_vm.entity.template))]) : _vm._e()], 1)]), _vm.can(_vm.permissions.maintain) ? _c("div", { staticClass: "body mx-5" }, [_c("a-row", { attrs: { "gutter": [24, 24] } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("create-label", { on: { "reload": _vm.forceRerender } })], 1)], 1)], 1) : _vm._e(), _c("list-label", { key: _vm.componentKey }), _c("router-view")], 1);
};
var staticRenderFns$1 = [];
var ProcessLabelFormat_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ProcessLabelFormat",
  inject: ["crud", "redirectRoute", "can"],
  components: {
    CreateLabel,
    ListLabel
  },
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      componentKey: "list-label-0",
      isSubmitting: false,
      entity: {},
      editForm: {},
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "label-formats",
          title: "Label Formats",
          path: "/container-filling/label-formats"
        }
      ]
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.crud.fetchEntity(), this.entity = this.crud.getEntity();
    } catch (err) {
      this.onBack();
    } finally {
      this.isLoading = false;
    }
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: `${this.entity && this.entity.name}`,
        title: `${this.entity && this.entity.name}`,
        path: `/container-filling/label-formats/${this.entity && this.entity.id}`
      }
    ];
  },
  methods: {
    onBack() {
      this.$router.push("/container-filling/label-formats");
    },
    forceRerender() {
      const index2 = this.componentKey.replace("list-label-", "");
      const nextIndex = +index2 + 1;
      this.componentKey = `list-label-${nextIndex}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2c46fb10", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ProcessLabelFormat = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "cf.label-formats", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/label-formats", "page": _vm.page } }, [_c("process-label-format")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ProcessLabelFormat
  },
  data() {
    return {
      page: CONTAINER_FILLING_LABELFORMAT,
      ProcessLabelFormat,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
